<template>
    <div class="page-box page">
        <Add></Add>
    </div>
    
</template>

<script>
import add from '../../scrm_pc/task/taskAdd'  
export default {
    components: {
        'Add': add  //将别名demo 变成 组件 Demo
    },
    data() {
        return {}
    },
    mounted: function(){},
}

</script>
<style scoped>
    .page-box{
        height: 100vh;
        background-color: #fff !important;
        padding: 15px;
    }
    
    
</style>
